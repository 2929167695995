// @mui material components
import Tooltip from "@mui/material/Tooltip";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiProgress from "components/VuiProgress";
import { FaFile, FaMagic, FaSave } from "react-icons/fa";
import { getFiles, setFiles } from "services/storage";
import VuiButton from "components/VuiButton";
import VuiBadge from "components/VuiBadge";
import { useGetMagicTags } from "hooks/useGetMagicTags";
import { useEffect } from "react";

export default function data(setIsLoading, setItemSelected) {
  const data = getFiles();
  const { isLoading, execute } = useGetMagicTags();
  
  const getMagicTags = async (cid) => {
    const magicTags = await execute(cid, 'ipfs.io');
    const newRows = data.rows.map(cidRow => {
      if (cidRow.ipfs_pin_hash === cid){
        cidRow.magicTags = magicTags;
      }
      return cidRow;
    });
    data.rows = newRows;
    setFiles(data);
  };

  useEffect(() => {
    setIsLoading(isLoading);    
  }, [isLoading]);

  const columns = [
    { name: "file", align: "left", width: "10%" },
    { name: "tags", align: "center"},
    { name: "explicit", align: "center" },
    { name: "actions", align: "center" },
  ];

  if (!data) {
    return {
      columns,
      rows: [
        {
          file: 'No data',
          CID: '',
          tags: '',
          explicit: '',  
          actions: '',
        },
      ],
    };
  }
  
  const getFormattedFile = (name, cid, row) => {
    return (
      <VuiBox display="flex" sx={{flexDirection: 'row'}} onClick={() => setItemSelected(row)}>
        <FaFile color="green" size={30} style={{paddingTop: 8}}/>
        <VuiBox display="flex" sx={{flexDirection: 'column'}}>
          <VuiTypography pl="10px" color="light" variant="button" fontWeight="medium">
            {name}
          </VuiTypography>
          <VuiTypography pl="10px" color="dark" variant="button" fontWeight="medium">
            {cid}
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    )
  };

  const getFormattedTags = (tags) => {
    if (!tags || tags.length === 0) {
      return (
        <VuiBox>
        </VuiBox>        
      );
    } else {
      return (
        <VuiBox display="flex" flexDirection="row" flexWrap="wrap" sx={{width: 300}}>
          {
            tags.map((tag) => {
              return (
                <VuiBadge
                  variant="standard"
                  badgeContent={tag}
                  color="success"
                  size="xs"
                  container
                  key={tag}
                  sx={({ palette: { white }, borders: { borderRadius, borderWidth } }) => ({
                    background: "unset",
                    border: `${borderWidth[1]} solid ${white.main}`,
                    borderRadius: borderRadius.md,
                    color: white.main,
                    margin: '3px',
                  })}
                />
              );  
            })
          }
        </VuiBox>
      )
    }

  };

  const getFormattedExplicit = (explicit) => {
    if(!explicit?.adult) {
      return(
        <VuiBox width="8rem" textAlign="left">
        </VuiBox>
      );
    } else {
      const adult = explicit?.adult || 0;
      const medical = explicit?.medical || 0;
      const racy = explicit?.racy || 0;
      const spoofed = explicit?.spoofed || 0;
      const violence = explicit?.violence || 0;
      const total = adult + medical + racy + spoofed + violence;
      const average = total / 5;
      return (
        <VuiBox width="8rem" textAlign="left">
          <VuiTypography color="white" variant="button" fontWeight="bold">
            {average} / 5
          </VuiTypography>
          <VuiProgress value={average*100/5} color="info" label={false} sx={{ background: "#2D2E5F" }} />
        </VuiBox>
      );
    }
  };

  const getActions = (cid) => {
    return (
      <VuiBox textAlign="left">
        <VuiButton
          color="light"
          size="medium"
          startIcon={<FaMagic />} 
          variant="text"
          onClick={() => getMagicTags(cid)}
        >
          &nbsp;
        </VuiButton>
        <VuiButton color="light" size="medium" startIcon={<FaSave />} variant="text" disabled>&nbsp;</VuiButton>
      </VuiBox>
    );
  };

  const rows = data.rows.map (row => {
    return {
      file: getFormattedFile(row?.metadata?.name || '<<empty>>', row?.ipfs_pin_hash, row),
      tags: getFormattedTags(row?.magicTags?.tags || []),
      explicit: getFormattedExplicit(row?.magicTags?.explicit || {}),
      actions: getActions(row?.ipfs_pin_hash),
    }
  });

  return {
    columns,
    rows,
  };
}
