import axios from 'axios';

const connection = axios.create({
  baseURL: 'https://magic-tags-lymfw5rs4a-uc.a.run.app/',
  timeout: 15000,
});

const getMagicTags = async (cid, gateway) => {
	const queryParams = `?gateway=${gateway}&cid=${cid}`;
	return connection.get(`getmagictags${queryParams}`);
}


export {getMagicTags};