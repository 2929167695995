import React from 'react';

import { Card } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import { IoHappy } from 'react-icons/io5';
import colors from 'assets/theme/base/colors';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';
import VuiBadge from 'components/VuiBadge';

const SatisfactionRate = ({itemSelected}) => {
	const { info, gradients } = colors;
	const { cardContent } = gradients;

	return (
		<Card sx={{ height: '340px' }}>
			<VuiBox display='flex' flexDirection='column'>
				<VuiTypography variant='lg' color='white' fontWeight='bold' mb='4px'>
					Tags
				</VuiTypography>
				{
					itemSelected?.metadata?.name && (
						<VuiTypography variant='button' color='text' fontWeight='regular' mb='20px'>
						{itemSelected?.metadata?.name}
						</VuiTypography>
					)
				}
	
				{
					<VuiBox display="flex" flexDirection="row" flexWrap="wrap" sx={{width: 300}}>
					{
					itemSelected?.magicTags?.tags.map((tag) => {
						return (
							<VuiBadge
								variant="standard"
								badgeContent={tag}
								color="success"
								size="xs"
								container
								key={tag}
								sx={({ palette: { white }, borders: { borderRadius, borderWidth } }) => ({
									background: "unset",
									border: `${borderWidth[1]} solid ${white.main}`,
									borderRadius: borderRadius.md,
									color: white.main,
									margin: '3px',
								})}
							/>
						);  
					})}
					</VuiBox>
				}
			</VuiBox>
		</Card>
	);
};

export default SatisfactionRate;
