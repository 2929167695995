import React from 'react';
import { Card, Stack } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiTypography from 'components/VuiTypography';
import colors from 'assets/theme/base/colors';
import { FaEllipsisH } from 'react-icons/fa';
import linearGradient from 'assets/theme/functions/linearGradient';
import CircularProgress from '@mui/material/CircularProgress';

function ReferralTracking({itemSelected}) {
	const { info, gradients } = colors;
	const { cardContent } = gradients;

	const adult = itemSelected?.magicTags?.explicit?.adult || 0;
	const medical = itemSelected?.magicTags?.explicit?.medical || 0;
	const racy = itemSelected?.magicTags?.explicit?.racy || 0;
	const spoofed = itemSelected?.magicTags?.explicit?.spoofed || 0;
	const violence = itemSelected?.magicTags?.explicit?.violence || 0;
	const total = adult + medical + racy + spoofed + violence;
	const average = total / 5;

	return (
		<Card
			sx={{
				height: '100%',
				background: linearGradient(gradients.cardDark.main, gradients.cardDark.state, gradients.cardDark.deg)
			}}>
			<VuiBox sx={{ width: '100%' }}>
				<VuiBox
					display='flex'
					alignItems='center'
					justifyContent='space-beetween'
					sx={{ width: '100%' }}
					mb='40px'>
					<VuiTypography variant='lg' color='white' mr='auto' fontWeight='bold'>
						Explicit content detection
					</VuiTypography>
				</VuiBox>
				<VuiBox
					display='flex'
					sx={({ breakpoints }) => ({
						[breakpoints.up('xs')]: {
							flexDirection: 'column',
							gap: '16px',
							justifyContent: 'center',
							alignItems: 'center'
						},
						[breakpoints.up('md')]: {
							flexDirection: 'row',
							justifyContent: 'flex-start',
							alignItems: 'center'
						}
					})}>
					<Stack
						direction='column'
						spacing='20px'
						width='500px'
						maxWidth='50%'
						sx={({ breakpoints }) => ({
							mr: 'auto',
							[breakpoints.only('md')]: {
								mr: '75px'
							},
							[breakpoints.only('xl')]: {
								width: '500px',
								maxWidth: '40%'
							}
						})}>
						<VuiBox
							display='flex'
							width='220px'
							flexDirection='column'
							sx={({ breakpoints }) => ({
								background: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
								borderRadius: '20px',
								[breakpoints.up('xl')]: {
									maxWidth: '110px !important'
								},
								[breakpoints.up('xxl')]: {
									minWidth: '180px',
									maxWidth: '100% !important'
								}
							})}>
							{
								itemSelected?.magicTags?.explicit?.adult && (
									<VuiTypography variant='button' color='text' fontWeight='regular'>
									 {`Adult: ${itemSelected?.magicTags.explicit.adult} / 5`}
									</VuiTypography>						
								)
							}
							{
								itemSelected?.magicTags?.explicit?.medical && (
									<VuiTypography variant='button' color='text' fontWeight='regular'>
										{`Medical: ${itemSelected?.magicTags.explicit.medical} / 5`}
									</VuiTypography>						
								)
							}
							{
								itemSelected?.magicTags?.explicit?.racy && (
									<VuiTypography variant='button' color='text' fontWeight='regular'>
										{`Racy: ${itemSelected?.magicTags.explicit.racy} / 5`}
									</VuiTypography>						
								)
							}
							{
								itemSelected?.magicTags?.explicit?.spoofed && (
									<VuiTypography variant='button' color='text' fontWeight='regular'>
									 {`Spoofed: ${itemSelected?.magicTags?.explicit?.spoofed} / 5`}
									</VuiTypography>						
								)
							}
							{
								itemSelected?.magicTags?.explicit?.violence && (
									<VuiTypography variant='button' color='text' fontWeight='regular'>
									 {`Violence: ${itemSelected?.magicTags?.explicit?.violence} / 5`}
									</VuiTypography>						
								)
							}
					</VuiBox>
					</Stack>
					<VuiBox sx={{ position: 'relative', display: 'inline-flex' }}>
						<CircularProgress
							variant='determinate'
							value={average * 100 / 5}
							size={window.innerWidth >= 1024 ? 200 : window.innerWidth >= 768 ? 170 : 200}
							color='success'
						/>
						<VuiBox
							sx={{
								top: 0,
								left: 0,
								bottom: 0,
								right: 0,
								position: 'absolute',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}>
							<VuiBox display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
								<VuiTypography color='text' variant='button' mb='4px'>
									average
								</VuiTypography>
								<VuiTypography
									color='white'
									variant='d5'
									fontWeight='bold'
									mb='4px'
									sx={({ breakpoints }) => ({
										[breakpoints.only('xl')]: {
											fontSize: '32px'
										}
									})}>
									{average}
								</VuiTypography>
								<VuiTypography color='text' variant='button'>
									Total Score
								</VuiTypography>
							</VuiBox>
						</VuiBox>
					</VuiBox>
				</VuiBox>
			</VuiBox>
		</Card>
	);
}

export default ReferralTracking;
