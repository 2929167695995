/*!

=========================================================
* Vision UI Free React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-react/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsCheckCircleFill } from "react-icons/bs";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard Materail-UI example components
import Table from "examples/Tables/Table";

// Data
import data from "layouts/dashboard/components/Files/data";
import AddPinataCidForm from "./AddPinataCidForm";
import { Box, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import WelcomeMark from "../WelcomeMark";
import SatisfactionRate from "../SatisfactionRate";
import ReferralTracking from "../ReferralTracking";

function Files() {
  const [menu, setMenu] = useState(null);
  const [importFromPinataShowForm, setImportFromPinataShowForm] = useState(false);
  const [fileData, setFileData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [itemSelected, setItemSelected] = useState(undefined);
  let { columns, rows } = data(setIsLoading, setItemSelected);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);
  const openClosePinataForm = () => {
    setImportFromPinataShowForm(!importFromPinataShowForm);
    closeMenu();    
  }

  useEffect(() => {
    if (itemSelected) handleOpen();
  }, [itemSelected]);
  
  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu} disabled>Add CID</MenuItem>
      <MenuItem onClick={openClosePinataForm}>Import from Pinata</MenuItem>
    </Menu>
  );

  return (
    <Card
      sx={{
        height: "100% !important",
      }}
    >
      <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="32px">
        <VuiBox mb="auto">
          <VuiTypography color="white" variant="lg" mb="6px" gutterBottom>
            Files
          </VuiTypography>
          {
            // <VuiBox display="flex" alignItems="center" lineHeight={0}>
            //   <BsCheckCircleFill color="green" size="15px" />
            //   <VuiTypography variant="button" fontWeight="regular" color="text" ml="5px">
            //     &nbsp;<strong>30 done</strong> this month
            //   </VuiTypography>
            // </VuiBox>
          }
        </VuiBox>
        <VuiBox color="text" px={2}>
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </VuiBox>
        {renderMenu}
      </VuiBox>
      {
        importFromPinataShowForm && (
          <VuiBox sx={{marginBottom: '20px'}}>
            <AddPinataCidForm setFileData={setFileData}/>
          </VuiBox>        
        )
      }
      <VuiBox
        sx={{
          "& th": {
            borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
              `${borderWidth[1]} solid ${grey[700]}`,
          },
          "& .MuiTableRow-root:not(:last-child)": {
            "& td": {
              borderBottom: ({ borders: { borderWidth }, palette: { grey } }) =>
                `${borderWidth[1]} solid ${grey[700]}`,
            },
          },
        }}
      >
        {
          isLoading ? (
            <VuiBox sx={{ display: 'flex', justifyContent: 'center', width:'100%',}}>
              <CircularProgress />
            </VuiBox>    
          ) : (
            <Table columns={columns} rows={rows} />            
          )
        }
      </VuiBox>
      <Modal
        sx={{
          width: '100%',
          height: '100%',
          boxShadow: 24,
          p: 4,
          bgcolor: 'RGBA(0,0,0,0.7)',
        }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{display: 'flex', direction: 'column', width: '100%', height: '100%', alignItems: 'center'}}>
          <Grid container spacing="18px">
            <Grid item xs={12} lg={12} xl={5}>
              <WelcomeMark itemSelected={itemSelected}/>
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <SatisfactionRate itemSelected={itemSelected} />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
              <ReferralTracking itemSelected={itemSelected}/>
            </Grid>
          </Grid>
        </Box>
      </Modal>      
    </Card>
  );
}

export default Files;
