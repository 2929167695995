const setSession = (sessionName) => {
  return localStorage.setItem("mt-session", JSON.stringify(sessionName));
};

const getSession = () => {
  return JSON.parse(localStorage.getItem("mt-session"));
};

const setFiles = (files) => {
  return localStorage.setItem(JSON.parse(localStorage.getItem("mt-session")), JSON.stringify(files));
};

const getFiles = () => {
  return JSON.parse(localStorage.getItem(JSON.parse(localStorage.getItem("mt-session"))));
};

export { setSession, getSession, setFiles, getFiles };
