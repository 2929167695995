import axios from 'axios';

const connection = axios.create({
  baseURL: 'https://api.pinata.cloud/',
  timeout: 5000,
});

const testAuthentication = async (JWT) => {
	const config = {
		headers: {
			'Authorization': `Bearer ${JWT}`,
		}
	}
	return connection.get('data/testAuthentication', config);
}

const getPinList = async (JWT) => {
	const config = {
		headers: {
			'Authorization': `Bearer ${JWT}`,
		}
	}
	return connection.get('data/pinList?status=pinned&pinSizeMin=20', config);
}

export {testAuthentication, getPinList};