import { Grid } from '@mui/material';
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';
import React from 'react';
import { IoDocumentText } from "react-icons/io5";
import { FaTags } from "react-icons/fa";
import { FaBan } from "react-icons/fa";

const MiniStatisticsCardsBlock = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} xl={4}>
        <MiniStatisticsCard
          title={{ text: "total files" }}
          count="10"
          percentage={{ color: "success", text: "100%" }}
          icon={{ color: "info", component: <IoDocumentText size="22px" color="white" /> }}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <MiniStatisticsCard
          title={{ text: "Tagged files", fontWeight: "regular" }}
          count="10"
          percentage={{ color: "success", text: "55%" }}
          icon={{ color: "info", component: <FaTags size="22px" color="white" /> }}
        />
      </Grid>
      <Grid item xs={12} md={6} xl={4}>
        <MiniStatisticsCard
          title={{ text: "explicit content files" }}
          count="0"
          percentage={{ color: "success", text: "0%" }}
          icon={{ color: "info", component: <FaBan size="20px" color="white" /> }}
        />
      </Grid>
    </Grid>
  )
}

export default MiniStatisticsCardsBlock;