import React, {useEffect, useState} from 'react';
import { Box, CircularProgress, Grid } from '@mui/material';
import VuiBox from 'components/VuiBox';
import VuiButton from 'components/VuiButton';
import VuiInput from 'components/VuiInput';
import VuiTypography from 'components/VuiTypography';
import { useGetPinList } from 'hooks/useGetPinList';
import { toast } from 'react-hot-toast';
import { setFiles } from 'services/storage';

const AddPinataCidForm = ({setFileData}) => {
  const [jwt, setJwt] = useState();
  const {data, error, isLoading, execute} = useGetPinList();

  const handleSubmit = () => {
    if(!jwt) {
      toast.error("Fill the JWT field");
    } else {
      execute(jwt);
    }
    setJwt(undefined);
  };
  
  const keyDownHandle = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  }

  useEffect(() => {
    error && toast.error("Error");
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success("Success");
      setFiles(data);
      setFileData(data);      
    }
  }, [data]);

  if (isLoading){
    return (
      <VuiBox sx={{ display: 'flex', justifyContent: 'center', width:'100%',}}>
        <CircularProgress />
      </VuiBox>
    )
  }

  return (
    <VuiBox component="form" role="form">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={1} md={10}>
          <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            Pinata JWT
          </VuiTypography>
          <VuiInput type="text" placeholder="eyJhbGci..." fontWeight="500" onChange={(e) => setJwt(e.target.value)} onKeyDown={keyDownHandle}/>
        </Grid>
        <Grid item xs={1} md={2}>
          <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
            &nbsp;
          </VuiTypography>
          <VuiButton color="success" sx={{width: "100%"}} onClick={handleSubmit}>
            CONFIRM
          </VuiButton>
        </Grid>
      </Grid>    
    </VuiBox>
  )
}

export default AddPinataCidForm;
